import Footer from "../komponenty/Footer"
import Header from "../komponenty/Header"
import { Outlet } from "react-router-dom"

const SharedLayout = () => {
  return <>
    <Header />
    <Outlet />
    <Footer />
  </>
}

export default SharedLayout