import { NavLink } from "react-router-dom"
import "./NavBar.scss"


const NavBar = () => {
  return <nav>
    <NavLink to="/">Home</NavLink>       
    <NavLink to="https://cyklo.sao-tatry.sk">Online Systém</NavLink>        
    <NavLink to="stavba">Stavebné práce</NavLink>        
    <NavLink to="foto">Foto služby</NavLink>        
    <NavLink to="obrazy">Maľovanie obrazov</NavLink>        
    <NavLink to="udrzba">Údržbrské práce</NavLink>        
    <NavLink to="e-shop">EShop</NavLink>        
  </nav>
}

export default NavBar