import{ BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./stranky/Home"
import SharedLayout from "./stranky/SharedLayout"
import Cyklo  from "./stranky/Cyklo"
import Eshop  from "./stranky/Eshop"
import Foto  from "./stranky/Foto"
import Obrazy  from "./stranky/Obrazy"
import Stavba  from "./stranky/Stavba"
import Udrzba  from "./stranky/Udrzba"
import Nieje  from "./stranky/Nieje"




const App = () => {
  return <BrowserRouter>
      <Routes>
          <Route path="/" element={ <SharedLayout /> }>
            <Route index element={<Home/>} />
            <Route path="cyklo" element={<Cyklo/>} />
            <Route path="stavba" element={<Stavba/>} />
            <Route path="foto" element={<Foto/>} />
            <Route path="obrazy" element={<Obrazy/>} />
            <Route path="udrzba" element={<Udrzba/>} />
            <Route path="e-shop" element={<Eshop/>} />
            <Route path="*" element={<Nieje/>} />
          </Route>
      </Routes>
    </BrowserRouter>
}
export default App